import * as React from 'react';
import { Helmet } from 'react-helmet';

import { ContactComponent } from '../components/contact/contact.component';
import { MainLayout } from '../layouts/MainLayout';
import { Seo } from '../components/shared/Seo/Seo';
import { ContentPageWrapper } from '../layouts/page-layouts/content-page';
import { Wrapper } from '../layouts/page-layouts/wrapper';
import { PageHeadingMain, PageHeading2, PageHeading3 } from '../components/shared/PageHeaders';
import { PageSection } from '../layouts/page-layouts/page-section';
import { NSiteCTA } from '../ncomponents/shared/sitecta/sitecta';
import { NServices } from '../ncomponents/shared/services/services';

interface ServicesPageProps { }

export default class extends React.Component<ServicesPageProps, any> {
    constructor(props: ServicesPageProps) {
        super(props);
    }

    public componentDidMount() {
        // this.mountWebflowJs();
    }

    private mountWebflowJs() {
        const scriptJq = document.createElement("script");
        scriptJq.src = `https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js`;
        //scriptJq.integrity = 'sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=';
    
    
        const script = document.createElement("script");
        script.src = `js/webflow.js`;
    
    
    
    
        document.body.appendChild(scriptJq);
        document.body.appendChild(script);
      }

    public render() {
        const pageTitle = `Services | Nuvious`;

        return (
            <MainLayout>
                <Seo path="/contact" />
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>


                <div>
                    <div className="section cc-home-wrap">
                        <div className="intro-header cc-subpage">
                            <div className="intro-content">
                                <div className="heading-jumbo">Services<br /></div>
                            </div>
                        </div>
                    </div>


                    <NServices />


                </div>


                <NSiteCTA />

            </MainLayout>
        );
    }
}
