import * as React from 'react';
import styled from 'styled-components';
import { DEBUG_LAYOUT, debugBorderRed } from '../../../global/layout-debug';


interface NSiteCTAProps {
    style?: React.CSSProperties;
}

export const NSiteCTA: React.StatelessComponent<
    NSiteCTAProps
> = (props: NSiteCTAProps) => {

    // This combines the incoming style with the debug border
    const style = { ...(props.style ? props.style : {}), ...(DEBUG_LAYOUT ? debugBorderRed : {}) };

    return (
        <div className="section cc-cta">
            <div className="container">
                <div className="cta-wrap">
                    <div>
                        <div className="cta-text">
                            <div className="heading-jumbo-small">Grow your business.<br /></div>
                            <div className="paragraph-bigger cc-bigger-light">Today is the day to build the business of your dreams. Share your mission with the world — and blow your customers away.<br /></div>
                        </div>
                        <a href="/contact" className="button cc-jumbo-button w-inline-block">
                            <div>Start Now</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
