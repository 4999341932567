import * as React from 'react';
import styled from 'styled-components';

import { DEBUG_LAYOUT, debugBorderRed } from '../../../global/layout-debug';



interface NServicesProps {
    style?: React.CSSProperties;
}

export const NServices: React.StatelessComponent<
    NServicesProps
> = (props: NServicesProps) => {

    // This combines the incoming style with the debug border
    const style = { ...(props.style ? props.style : {}), ...(DEBUG_LAYOUT ? debugBorderRed : {}) };

    return (
        <div id="services" className="div-block">
            <div className="section">
                <div className="container">
                    <div className="section-heading-wrap">
                        <div className="label cc-light">What we are best at</div>
                        <h2>Our Services</h2>
                    </div>
                    <div className="w-layout-grid">
                        <div id="w-node-f324050a31b1-050a31a3"><img src="images/data_blue.svg" width={50} alt="" className="service-icon" />
                            <div className="paragraph-bigger">Web Development</div>
                            <div className="paragraph-light">Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life</div>
                        </div>
                        <div id="w-node-f324050a31c3-050a31a3"><img src="images/mobile_blue.svg" width={50} alt="" className="service-icon" />
                            <div className="paragraph-bigger">Mobile App Development<br /></div>
                            <div className="paragraph-light">Blind texts it is an almost unorthographic life One day however a small line of blind text by the name</div>
                        </div>
                        <div id="w-node-f324050a31ab-050a31a3"><img src="images/correct_blue.svg" width={50} alt="" className="service-icon" />
                            <div className="paragraph-bigger">Training</div>
                            <div className="paragraph-light">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts</div>
                        </div>
                        <div id="w-node-f324050a31ca-050a31a3"><img src="images/chat_blue.svg" width={50} alt="" className="service-icon" />
                            <div className="paragraph-bigger">Consulting <br /></div>
                            <div className="paragraph-light">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
